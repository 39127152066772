import React from "react"

import ErrorLayout from "@components/error-layout"
import SEO from "@components/seo"

const NotFoundPage = () => (
  <ErrorLayout>
    <SEO title="Page not found" />

    <section className="stripe stripe--centered">
      <div className="container">
        <div className="copy">
          <a href="/" className="harper-logo">Harper</a>
          <div className="errorMessage">
            <h2 className="attribution">404</h2>

            <h1 className="pageTitle">Page not found</h1>
            <p className="lede">We can&rsquo;t sniff out that page.</p>
            <a href="/" className="btn btn--primary">Return to harper.dog</a>
          </div>
        </div>
      </div>
    </section>
  </ErrorLayout>
);

export default NotFoundPage
