import React from "react"
import PropTypes from "prop-types"

import BodyClass from "./body-class"
import "./layout.scss"

const ErrorLayout = ({
  children,
}) => {
  return (
    <BodyClass
      bodyClassName="error"
    >
      <main>
        {children}
      </main>

    </BodyClass>
  )
}

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorLayout
